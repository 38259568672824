@import url('https://fonts.googleapis.com/css2?family=Cinzel&family=Inter:wght@700&display=swap');

*{
	box-sizing: border-box;
}

body{
	background-color: black;
	color: white;
	font-family: 'Inter', sans-serif;
	text-transform: uppercase;
	overflow: hidden;
	&.is-paradise{
		.marquee-registered{
			display: block;
		}
		.paradise-back{
			opacity: 1;
		}
		.centerimage--overlay{
			opacity: 1;
			transform: translateY(0) translateZ(20px);
			transition: transform 0.3s ease;
		}
	}
}

.paradise-back{
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: -1;
	overflow: hidden;
	opacity: 0;
	transition: opacity 0.3s ease;
	img{
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.mouseovercontainer{
	position: absolute;
	left:0;
	top: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}
.centerimage{
	max-width: 66vw;
	max-height: 66vh;
}
.centerimage--overlay{
	position: absolute;
	max-width: 66vw;
	max-height: 66vh;
	z-index: 1;
	opacity: 0;
	transform: translateY(-100px);
}
.centerimagewrapper{
	animation: float 6s ease-in-out infinite;
}
.bottomnav{
	position: absolute;
	left: 10px;
	right: 10px;
	bottom: 10px;
	display: flex;
	justify-content: center;
	margin: 20px;
}
.register{
	display: block;
	border: 2px solid white;
	font-size: 2rem;
	padding: 0.5rem 2rem;
	cursor: pointer;
	border-radius: 2rem;
	color: inherit;
	text-decoration: none;
}

.marquee {
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: 5rem;
  overflow-x: hidden;
  font-size: 4rem;
  &.marquee-registered{
  	display: none;
  	position: absolute;
  	bottom: 6rem;
  }
}


.track {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee 32s linear infinite;
}

@keyframes float {
	0% {
		transform: translatey(20px);
	}
	50% {
		transform: translatey(-20px);
	}
	100% {
		transform: translatey(20px);
	}
}

@keyframes marquee {
  from { transform: translateX(0); }
  to { transform: translateX(-50%); }
}
